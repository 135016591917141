import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';

import Header from '../../../components/Header';

import Empty from '../../../../../assets/img/illustrations/empty.svg';
import api from '../../../../../shared/services/api';
import ReactLoading from 'react-loading';

interface IApplication {
  applications: [
    {
      id: string;
      job: {
        id: string;
        title: string;
        description: string;
        location: string;
        period: string;
      };
    },
  ];
}

interface IJob {
  id: string;
  title: string;
  description: string;
  location: string;
  period: string;
}

const MyJobs: React.FC = () => {
  AOS.init();

  const [user, setUser] = useState<IApplication>();
  const [job, setJob] = useState<IJob>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.get('/profile').then(response => {
      setUser(response.data);
    });
  }, []);

  useEffect(() => {
    user?.applications.map(application => {
      return setJob(application.job);
    });
  }, [user]);

  setTimeout(() => setLoading(false), 700);

  return (
    <>
      <Header />
      {loading && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '500px',
          }}
        >
          <ReactLoading type="bubbles" color="#335eea" width={100} />
        </div>
      )}
      {user && !loading && (
        <>
          {job && (
            <section style={{ marginTop: 50 }}>
              <h2 className="text-center">Minhas vagas</h2>

              <div className="container">
                <div className="row">
                  {user.applications.map(application => (
                    <div
                      className="col-12 col-md-4 col-lg-"
                      data-aos="fade-up"
                      key={application.id}
                    >
                      <div className="card   shadow-light mb-6 mb-md-8 lift lift-lg">
                        <div className="card-body text-center">
                          <h4 className="font-weight-bold">
                            {application.job.title}
                          </h4>

                          <p className="text-gray-700 mb-5">
                            {application.job.location} -{' '}
                            {application.job.period}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          )}
          {!job && (
            <section className="section-border border-primary">
              <div className="container d-flex flex-column">
                <div className="row align-items-center justify-content-center no-gutters ">
                  <div
                    className="col-8 col-md-6 col-lg-7 offset-md-1 order-md-2 mt-auto mt-md-0 pt-8 pb-4 py-md-11"
                    data-aos="fade-up"
                  >
                    <img src={Empty} alt="..." className="img-fluid" />
                  </div>
                  <div
                    className="col-12 col-md-5 col-lg-4 order-md-1 mb-auto mb-md-0 pb-8 py-md-11"
                    data-aos="fade-up"
                  >
                    <h1 className="display-3 font-weight-bold text-center">
                      Ooops
                    </h1>

                    <p className="mb-5 text-center text-muted">
                      parece que ainda não se candidatou em nenhuma vaga
                    </p>

                    <div className="text-center">
                      <Link className="btn btn-primary" to="/">
                        Ver todas as vagas
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
        </>
      )}
    </>
  );
};

export default MyJobs;
