import React from 'react';

import { useAuth } from '../../../../shared/hooks/auth';

const AccountHeader: React.FC = () => {
  const { signOut } = useAuth();

  return (
    <header className="bg-dark pt-9 pb-11 d-none d-md-block">
      <div className="container-md">
        <div className="row align-items-center">
          <div className="col">
            <h1 className="font-weight-bold text-white mb-2">Meu perfil</h1>
          </div>
          <div className="col-auto">
            <button
              type="button"
              onClick={signOut}
              className="btn btn-sm btn-gray-300-20"
            >
              Sair
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default AccountHeader;
