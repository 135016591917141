import React, { useCallback, useEffect, useState } from 'react';
import Aos from 'aos';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { useToast } from '../../../../../shared/hooks/toast';
import api from '../../../../../shared/services/api';
import DashboardHeader from '../../../components/DashboardHeader';

interface IChallenge {
  id: string;
  title: string;
}

const Challenges: React.FC = () => {
  Aos.init();

  const { addToast } = useToast();

  const [challenges, setChallenges] = useState<IChallenge[]>([]);

  useEffect(() => {
    api.get('/challenges').then(response => {
      setChallenges(response.data);
    });
  }, []);

  const handleDeleteChallenge = useCallback(
    async (challenge_id: string) => {
      try {
        await api.delete(`/challenges/${challenge_id}`);

        console.log(challenge_id);

        setChallenges(
          challenges.filter(challenge => challenge.id !== challenge_id),
        );

        addToast({
          type: 'success',
          title: 'Desafio deletado',
          description: 'O desafio foi deletado com sucesso',
        });
      } catch {
        addToast({
          type: 'error',
          title: 'Erro ao deletar o desafio',
        });
      }
    },
    [addToast, challenges],
  );

  return (
    <>
      <DashboardHeader />
      <header className="bg-dark pt-9 pb-11 d-none d-md-block">
        <div className="container-md">
          <div className="row align-items-center">
            <div className="col">
              <h1 className="font-weight-bold text-white mb-2">Desafios</h1>
            </div>
            <div className="col-auto">
              <Link
                to="/dashboard/new-challenge"
                className="btn btn-sm btn-success"
              >
                Criar novo desafio
              </Link>
            </div>
          </div>
        </div>
      </header>
      <main className="pb-8 pb-md-11 mt-md-n6" style={{ margin: 30 }}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12" data-aos="fade-up">
              <div
                className="card card-bleed shadow-light-lg mb-6"
                data-aos="fade-up"
              >
                <div className="card-header">
                  <h4 className="mb-0">Todos os desafios</h4>
                </div>
                <div className="card-body">
                  <div className="card mb-5">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">Nome</th>
                              <th scope="col" />
                            </tr>
                          </thead>
                          <tbody>
                            {challenges.map(challenge => (
                              <tr key={challenge.id}>
                                <td>{challenge.title}</td>
                                <td>
                                  <div
                                    className="col-lg-6 col-5 text-right"
                                    style={{
                                      display: 'flex',
                                    }}
                                  >
                                    <Link
                                      to={`/dashboard/edit-challenge/${challenge.id}`}
                                      className="btn btn-sm btn-rounded-circle btn-secondary text-white"
                                      style={{ marginRight: 10 }}
                                    >
                                      <FiEdit />
                                    </Link>

                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleDeleteChallenge(challenge.id)}
                                      className="btn btn-sm btn-rounded-circle btn-danger text-white"
                                    >
                                      <FiTrash2 />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Challenges;
