import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Form } from '@unform/web';
import Aos from 'aos';
import 'react-quill/dist/quill.snow.css';
import Input from '../../../../../shared/components/Input';
import { FormHandles } from '@unform/core';
import { useToast } from '../../../../../shared/hooks/toast';
import { useHistory, useRouteMatch } from 'react-router-dom';
import api from '../../../../../shared/services/api';
import * as Yup from 'yup';
import getValidationErrors from '../../../../../shared/utils/getValidationErrors';
import DashboardHeader from '../../../components/DashboardHeader';
import md from '../../../../../config/mdRenderer';
import ReactQuill from 'react-quill';

interface IParams {
  challenge: string;
}

interface IChallengeData {
  title: string;
}

interface IChallenge {
  id: string;
  title: string;
  description: string;
}

const EditChallenge: React.FC = () => {
  Aos.init();

  const { params } = useRouteMatch<IParams>();

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const history = useHistory();

  const [challenge, setChallenge] = useState<IChallenge>();
  const [description, setDescription] = useState<string>();
  const html = useMemo(() => md.render(challenge?.description || ''), [
    challenge,
  ]);

  useEffect(() => {
    api.get(`/challenges/${params.challenge}`).then(response => {
      setChallenge(response.data);
    });
  }, [params.challenge]);

  const handleSubmit = useCallback(
    async (data: IChallengeData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          title: Yup.string().required('Nome obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { title } = data;

        await api.put(`/challenges/${params.challenge}`, {
          title,
          description,
        });

        history.push('/dashboard/challenges');

        addToast({
          type: 'success',
          title: 'Desafio Atualizado',
          description: 'O desafio foi atualizado com sucesso',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
        addToast({
          type: 'error',
          title: 'Erro ao atualizar o desafio',
        });
      }
    },
    [addToast, history, description, params.challenge],
  );

  return (
    <>
      {challenge && (
        <>
          <DashboardHeader />
          <header className="bg-dark pt-9 pb-11 d-none d-md-block">
            <div className="container-md">
              <div className="row align-items-center">
                <div className="col">
                  <h1 className="font-weight-bold text-white mb-2">
                    Editar desafio
                  </h1>
                </div>
              </div>
            </div>
          </header>
          <main className="pb-8 pb-md-11 mt-md-n6" style={{ margin: 30 }}>
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-12" data-aos="fade-up">
                  <div
                    className="card card-bleed shadow-light-lg mb-6"
                    data-aos="fade-up"
                  >
                    <div className="card-header">
                      <h4 className="mb-0">Editar Desafio</h4>
                    </div>
                    <div className="card-body">
                      <Form
                        onSubmit={handleSubmit}
                        ref={formRef}
                        initialData={challenge}
                      >
                        <div className="row">
                          <div className="col-12 col-md-12">
                            <div className="form-group">
                              <label htmlFor="name">Nome</label>
                              <Input name="title" type="text" />
                            </div>
                          </div>
                          <div className="col-12 col-md-12">
                            <div className="form-group">
                              <label htmlFor="email">Descrição</label>
                              <ReactQuill
                                onChange={setDescription}
                                theme="snow"
                                placeholder="Um pouco sobre o desafio..."
                                defaultValue={html}
                              />
                            </div>
                          </div>
                          <div className="col-12 ">
                            <button
                              className="btn btn-block btn-primary"
                              type="submit"
                            >
                              Confirmar alterações
                            </button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </>
      )}
    </>
  );
};

export default EditChallenge;
