import React, {
  useRef,
  useState,
  ChangeEvent,
  useCallback,
  useMemo,
} from 'react';
import md from '../../../../../config/mdRenderer';

import Header from '../../../components/Header';
import ReactQuill from 'react-quill';
import { useAuth } from '../../../../../shared/hooks/auth';
import { useToast } from '../../../../../shared/hooks/toast';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import 'react-quill/dist/quill.snow.css';
import AccountHeader from '../../../components/AccountHeader';
import AccountSideBar from '../../../components/AccountSideBar';
import Avatar from '../../../../../assets/img/illustrations/profile.svg';
import api from '../../../../../shared/services/api';
import getValidationErrors from '../../../../../shared/utils/getValidationErrors';
import Input from '../../../../../shared/components/Input';
import ReactLoading from 'react-loading';
import AOS from 'aos';

interface ProfileData {
  name: string;
  email: string;
  old_password: string;
  password: string;
  password_confirmation: string;
}

const Profile: React.FC = () => {
  AOS.init();

  const history = useHistory();

  const formRef = useRef<FormHandles>(null);
  const [about, setAbout] = useState<string>('');
  const [loading, setLoading] = useState(true);

  const { user, updateUser } = useAuth();
  const { addToast } = useToast();
  const html = useMemo(() => md.render(user?.about || ''), [user]);

  const handleSubmit = useCallback(
    async (data: ProfileData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('E-mail inválido'),
          password: Yup.string().when('old_password', {
            is: val => !!val.length,
            then: Yup.string().required(),
            otherwise: Yup.string(),
          }),
          old_password: Yup.string(),
          password_confirmation: Yup.string()
            .when('old_password', {
              is: val => !!val.length,
              then: Yup.string().required(),
              otherwise: Yup.string(),
            })
            .oneOf([Yup.ref('password')], 'Confirmação incorreta'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const {
          name,
          email,
          old_password,
          password,
          password_confirmation,
        } = data;

        const formData = {
          name,
          email,
          ...(old_password
            ? {
                old_password,
                password,
                password_confirmation,
              }
            : {}),
        };

        const response = await api.put('/profile', formData);

        updateUser(response.data);

        history.push('/profile/me');

        addToast({
          type: 'success',
          title: 'Perfil atualizado',
          description: 'Suas informações foram atualizadas com sucesso',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
        addToast({
          type: 'error',
          title: 'Erro ao atualizar perfil',
          description: 'Cheque suas credenciais e tente novamente',
        });
      }
    },
    [addToast, history, updateUser],
  );

  const handleAvatarChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const data = new FormData();

        data.append('avatar', e.target.files[0]);

        api.patch('/users/avatar', data).then(response => {
          updateUser(response.data);

          addToast({
            type: 'success',
            title: 'Avatar atualizado com sucesso',
          });
        });
      }
    },
    [addToast, updateUser],
  );

  const updateUserAbout = useCallback(async () => {
    try {
      const response = await api.patch('/users/about', {
        about,
      });

      addToast({
        type: 'success',
        title: 'Seção atualizada com sucesso',
        description: 'Sua sessão sobre você já foi atualizada',
      });

      updateUser(response.data);
    } catch {
      addToast({
        type: 'error',
        title: 'Erro ao atualizar sua seção sobre',
      });
    }
  }, [about, addToast, updateUser]);

  setTimeout(() => setLoading(false), 700);

  return (
    <>
      <Header />
      {loading && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '500px',
          }}
        >
          <ReactLoading type="bubbles" color="#335eea" width={100} />
        </div>
      )}
      {!loading && (
        <>
          <AccountHeader />
          <main className="pb-8 pb-md-11 mt-md-n6">
            <div className="container-md">
              <div className="row">
                <AccountSideBar />
                <div className="col-12 col-md-9" data-aos="fade-up">
                  <div className="card card-bleed shadow-light-lg mb-6  ">
                    <div className="card-header">
                      <h4 className="mb-0">Informações basicas</h4>
                    </div>
                    <div className="card-body">
                      <Form
                        onSubmit={handleSubmit}
                        ref={formRef}
                        initialData={{
                          name: user.name,
                          email: user.email,
                        }}
                      >
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <div className="form-group">
                              <label htmlFor="name">Nome</label>
                              <Input
                                name="name"
                                id="name"
                                type="text"
                                placeholder={user.name}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="form-group">
                              <label htmlFor="email">E-mail</label>
                              <Input
                                name="email"
                                id="email"
                                type="email"
                                placeholder={user.email}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="form-group">
                              <label htmlFor="email">Senha atual</label>
                              <Input
                                name="old_password"
                                id="email"
                                type="password"
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="form-group">
                              <label htmlFor="email">Nova senha</label>
                              <Input
                                name="password"
                                id="email"
                                type="password"
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="form-group">
                              <label htmlFor="email">Confirmar senha</label>
                              <Input
                                name="password_confirmation"
                                id="email"
                                type="password"
                              />
                            </div>
                          </div>
                          <div className="col-12 ">
                            <button
                              className="btn btn-block btn-primary"
                              type="submit"
                            >
                              Confirmar alterações
                            </button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>

                  <div
                    className="card card-bleed shadow-light-lg mb-6"
                    data-aos="fade-up"
                  >
                    <div className="card-header">
                      <h4 className="mb-0">Sobre mim</h4>
                    </div>
                    <div className="card-body">
                      <div className="list-group list-group-flush">
                        <div className="list-group-item">
                          <div className="row align-items-center">
                            <div className="col-auto">
                              <div className="avatar avatar-xl">
                                {user.avatar_url ? (
                                  <img
                                    className="avatar-img rounded-circle"
                                    src={user.avatar_url}
                                    alt="..."
                                  />
                                ) : (
                                  <img
                                    className="avatar-img rounded-circle"
                                    src={Avatar}
                                    alt="..."
                                  />
                                )}
                              </div>
                            </div>
                            <div className="col ml-n5">
                              <p className="mb-0">Seu avatar</p>

                              <small className="text-gray-700">
                                PNG ou JPG
                              </small>
                            </div>
                            <div className="col-12 col-md-auto">
                              <label htmlFor="avatar">
                                <p className="btn btn-xs btn-block btn-primary mt-5 mt-md-0">
                                  <input
                                    type="file"
                                    id="avatar"
                                    onChange={handleAvatarChange}
                                    style={{ opacity: 0, width: 0 }}
                                  />
                                  Carregar imagem
                                </p>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="list-group-item">
                          <div
                            className="dropzone dropzone-single"
                            data-dropzone='{"url": "https://", "maxFiles": 1, "acceptedFiles": "image/*"}'
                          >
                            <div className="dz-preview dz-preview-single" />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <ReactQuill
                              onChange={setAbout}
                              theme="snow"
                              defaultValue={html}
                              placeholder="Um pouco sobre você...."
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-auto">
                          <button
                            className="btn btn-block btn-primary"
                            onClick={updateUserAbout}
                            type="submit"
                          >
                            Confirmar alterações
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <p className="text-center mb-0">
                    <small className="text-muted">
                      Se você não quoser mais usar o Finance Cores jobs{' '}
                      <a className="text-danger" href="#!">
                        delete sua conta
                      </a>
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </main>
        </>
      )}
    </>
  );
};

export default Profile;
