import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import Aos from 'aos';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Input from '../../../../../shared/components/Input';
import { FormHandles } from '@unform/core';
import { useToast } from '../../../../../shared/hooks/toast';
import { useHistory } from 'react-router-dom';
import Select from '../../../../../shared/components/Select';
import api from '../../../../../shared/services/api';
import * as Yup from 'yup';
import getValidationErrors from '../../../../../shared/utils/getValidationErrors';
import DashboardHeader from '../../../components/DashboardHeader';

interface IChallenge {
  id: string;
  title: string;
}

interface IJobData {
  title: string;
  period: string;
  location: string;
  challenge_id: string;
  category_id: string;
}

const CreateJob: React.FC = () => {
  Aos.init();

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const history = useHistory();

  const [challenges, setChallenges] = useState<IChallenge[]>([]);
  const [description, setDescription] = useState<string>();
  const [categories, setCategories] = useState<IChallenge[]>([]);

  useEffect(() => {
    api.get('/challenges').then(response => {
      setChallenges(response.data);
    });
  }, []);

  useEffect(() => {
    api.get('/categories').then(response => {
      setCategories(response.data);
    });
  }, []);

  const handleSubmit = useCallback(
    async (data: IJobData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          title: Yup.string().required('Nome obrigatório'),
          location: Yup.string().required('Local obrigatório'),
          period: Yup.string().required('Periodo obrigatório'),
          challenge_id: Yup.string().required(),
          category_id: Yup.string().required(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { title, location, period, category_id, challenge_id } = data;

        await api.post('/jobs', {
          title,
          location,
          period,
          category_id,
          challenge_id,
          description,
        });

        history.push('/dashboard/jobs');

        addToast({
          type: 'success',
          title: 'Vaga criada',
          description: 'Uma nova foi criada  com sucesso',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
        addToast({
          type: 'error',
          title: 'Erro ao criar vaga',
        });
      }
    },
    [addToast, history, description],
  );

  return (
    <>
      <DashboardHeader />
      <header className="bg-dark pt-9 pb-11 d-none d-md-block">
        <div className="container-md">
          <div className="row align-items-center">
            <div className="col">
              <h1 className="font-weight-bold text-white mb-2">Nova vaga</h1>
            </div>
          </div>
        </div>
      </header>
      <main className="pb-8 pb-md-11 mt-md-n6" style={{ margin: 30 }}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12" data-aos="fade-up">
              <div
                className="card card-bleed shadow-light-lg mb-6"
                data-aos="fade-up"
              >
                <div className="card-header">
                  <h4 className="mb-0">Criar nova vaga</h4>
                </div>
                <div className="card-body">
                  <Form onSubmit={handleSubmit} ref={formRef}>
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label htmlFor="name">Nome</label>
                          <Input name="title" id="name" type="text" />
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label htmlFor="email">Local</label>
                          <Input name="location" />
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label htmlFor="email">Período</label>
                          <Input name="period" />
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label htmlFor="email">Desafio</label>
                          <Select name="challenge_id" id="email" type="select">
                            {challenges.map(challenge => (
                              <option key={challenge.id} value={challenge.id}>
                                {challenge.title}
                              </option>
                            ))}
                          </Select>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label htmlFor="email">Categoria</label>
                          <Select name="category_id" id="email" type="password">
                            {categories.map(category => (
                              <option key={category.id} value={category.id}>
                                {category.title}
                              </option>
                            ))}
                          </Select>
                        </div>
                      </div>
                      <div className="col-12 col-md-12">
                        <div className="form-group">
                          <label htmlFor="email">Descrição</label>
                          <ReactQuill
                            onChange={setDescription}
                            theme="snow"
                            placeholder="Um pouco sobre a vaga..."
                          />
                        </div>
                      </div>
                      <div className="col-12 ">
                        <button
                          className="btn btn-block btn-primary"
                          type="submit"
                        >
                          Confirmar alterações
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default CreateJob;
