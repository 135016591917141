import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './route';

import Home from '../../../modules/users/pages/jobs/Home';
import JobDetail from '../../../modules/users/pages/jobs/JobDetail';
import SignIn from '../../pages/SignIn';
import SignUp from '../../pages/SignUp';
import Jobs from '../../../modules/admin/pages/jobs/Jobs';
import MyChallenges from '../../../modules/users/pages/challenges/MyChallenges';
import MyJobs from '../../../modules/users/pages/jobs/MyJobs';
import ChallengeDetail from '../../../modules/users/pages/challenges/ChallengeDetail';
import Profile from '../../../modules/users/pages/user/Profile';
import Challenges from '../../../modules/admin/pages/challenges/Challenges';
import CreateJob from '../../../modules/admin/pages/jobs/CreateJob';
import Categories from '../../../modules/admin/pages/jobs/Categories';
import CreateCategory from '../../../modules/admin/pages/jobs/CreateCategory';
import CreateChallenge from '../../../modules/admin/pages/challenges/CreateChallenge';
import AppliedUsers from '../../../modules/admin/pages/jobs/AppliedUsers';
import EditJob from '../../../modules/admin/pages/jobs/EditJob';
import EditCategory from '../../../modules/admin/pages/jobs/EditCategory';
import EditChallenge from '../../../modules/admin/pages/challenges/EditChallenge';
import AppliedUserDetail from '../../../modules/admin/pages/jobs/AppliedUserDetail';
import AppliedUserChallenge from '../../../modules/admin/pages/jobs/AppliedUserChallenge';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />
    <Route path="/cadastrar" exact component={SignUp} />

    <Route path="/home" component={Home} permission="default" />
    <Route path="/carrer/:job+" component={JobDetail} permission="default" />
    <Route path="/jobs/me" component={MyJobs} permission="default" />
    <Route path="/profile/me" component={Profile} permission="default" />
    <Route
      path="/challenges/me"
      component={MyChallenges}
      permission="default"
    />
    <Route
      path="/challenge/:challenge+"
      component={ChallengeDetail}
      permission="default"
    />

    <Route path="/dashboard/jobs" component={Jobs} permission="admin" />
    <Route
      path="/dashboard/categories"
      component={Categories}
      permission="admin"
    />
    <Route path="/dashboard/new-job" component={CreateJob} permission="admin" />
    <Route
      path="/dashboard/edit-job/:job+"
      component={EditJob}
      permission="admin"
    />
    <Route
      path="/dashboard/new-category"
      component={CreateCategory}
      permission="admin"
    />
    <Route
      path="/dashboard/edit-category/:category+"
      component={EditCategory}
      permission="admin"
    />
    <Route
      path="/dashboard/new-challenge"
      component={CreateChallenge}
      permission="admin"
    />
    <Route
      path="/dashboard/edit-challenge/:challenge+"
      component={EditChallenge}
      permission="admin"
    />
    <Route
      path="/dashboard/challenges"
      component={Challenges}
      permission="admin"
    />
    <Route
      path="/dashboard/applied-users/:job+"
      component={AppliedUsers}
      permission="admin"
    />
    <Route
      path="/dashboard/applied-user/:user+"
      component={AppliedUserDetail}
      permission="admin"
    />
    <Route
      path="/dashboard/applied-user-challenges/:user+"
      component={AppliedUserChallenge}
      permission="admin"
    />
  </Switch>
);

export default Routes;
