import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../../shared/hooks/auth';
import Avatar from '../../../../assets/img/illustrations/profile.svg';

const Header: React.FC = () => {
  const { user } = useAuth();

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white border-bottom ">
      <div className="container">
        <a className="navbar-brand" href="/">
          <img src="" className="navbar-brand-img" alt="..." />
        </a>

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbarCollapse">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fe fe-x" />
          </button>

          <ul className="navbar-nav ml-auto">
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                id="navbarLandings"
                data-toggle="dropdown"
                style={{ fontSize: 16 }}
                to="/home"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Vagas
              </Link>
            </li>
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                id="navbarPages"
                data-toggle="dropdown"
                style={{ fontSize: 16 }}
                to="/jobs/me"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Minhas vagas
              </Link>
            </li>
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                id="navbarAccount"
                style={{ fontSize: 16 }}
                data-toggle="dropdown"
                to="/challenges/me"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Desafios
              </Link>
            </li>
          </ul>
          {/* <a */}
          {/*  className="nav-link  ml-auto login-link" */}
          {/*  id="navbarAccount" */}
          {/*  data-toggle="dropdown" */}
          {/*  aria-haspopup="true" */}
          {/*  aria-expanded="false" */}
          {/* > */}
          {/*  Olá, {user.name} */}
          {/* </a> */}
          <div className="row align-items-center ml-auto">
            <div className="col-auto">
              <div className="avatar avatar-lg">
                {user.avatar_url ? (
                  <img
                    className="avatar-img rounded-circle"
                    src={user.avatar_url}
                    alt="..."
                  />
                ) : (
                  <img
                    className="avatar-img rounded-circle"
                    src={Avatar}
                    alt="..."
                  />
                )}
              </div>
            </div>
            <div className="col ml-n5">
              <Link
                style={{ textDecoration: 'none', color: '#506690' }}
                to="/profile/me"
                className="mb-0"
              >
                {user.name}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
