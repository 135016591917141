import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../../shared/hooks/auth';

const DashboardHeader: React.FC = () => {
  const { signOut } = useAuth();

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white border-bottom">
      <div className="container">
        <a className="navbar-brand" href="/">
          <img src="" className="navbar-brand-img" alt="..." />
        </a>

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbarCollapse">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fe fe-x" />
          </button>

          <ul className="navbar-nav ml-auto">
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                id="navbarLandings"
                data-toggle="dropdown"
                style={{ fontSize: 16 }}
                to="/dashboard/jobs"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Vagas
              </Link>
            </li>
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                id="navbarPages"
                data-toggle="dropdown"
                style={{ fontSize: 16 }}
                to="/dashboard/categories"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Categorias
              </Link>
            </li>
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                id="navbarAccount"
                style={{ fontSize: 16 }}
                data-toggle="dropdown"
                to="/dashboard/challenges"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Desafios
              </Link>
            </li>
          </ul>
          {/* <a */}
          {/*  className="nav-link  ml-auto login-link" */}
          {/*  id="navbarAccount" */}
          {/*  data-toggle="dropdown" */}
          {/*  aria-haspopup="true" */}
          {/*  aria-expanded="false" */}
          {/* > */}
          {/*  Olá, {user.name} */}
          {/* </a> */}
          <div className="row align-items-center ml-auto">
            <div className="col ml-n5">
              <button
                type="button"
                onClick={signOut}
                className="btn btn-sm btn-danger"
                style={{ fontSize: 14 }}
              >
                Sair
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default DashboardHeader;
