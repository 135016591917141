import React, { useRef, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import getValidationErrors from '../../utils/getValidationErrors';
import AOS from 'aos';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import Input from '../../components/Input';

interface SignInFormData {
  name: string;
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  AOS.init();

  const { signIn } = useAuth();
  const { addToast } = useToast();

  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('E-mail inválido'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn({
          email: data.email,
          password: data.password,
        });

        history.push('/');

        addToast({
          type: 'success',
          title: 'Bem vindo',
          description: 'Login realizado com sucesso',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description:
            'Ocorreu um erro ao fazer login, cheque suas credenciais e tente novamente',
        });
      }
    },
    [signIn, history, addToast],
  );

  return (
    <section className="section-border border-primary">
      <div className="container d-flex flex-column">
        <div className="row align-items-center justify-content-center no-gutters min-vh-100">
          <div
            className="col-12 col-md-5 col-lg-4 py-8 py-md-11"
            data-aos="fade-up"
          >
            <h1 className="mb-0 font-weight-bold text-center">Log in</h1>

            <p className="mb-6 text-center text-muted">
              Se candidate em uma das nossas vagas em minutos
            </p>

            <Form ref={formRef} onSubmit={handleSubmit} className="mb-6">
              <div className="form-group">
                <label htmlFor="email">E-mail</label>
                <Input
                  name="email"
                  className="form-control"
                  id="email"
                  placeholder="nome@email.com.br"
                />
              </div>

              <div className="form-group mb-5">
                <label htmlFor="password">Senha</label>
                <Input
                  name="password"
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder="Sua senha"
                />
              </div>

              <button className="btn btn-block btn-primary" type="submit">
                Entrar
              </button>
            </Form>

            <p className="mb-0 font-size-sm text-center text-muted">
              Ainda não tem uma conta? <Link to="/cadastrar">Criar conta</Link>.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignIn;
