import React, { useCallback, useRef } from 'react';
import { Form } from '@unform/web';
import Aos from 'aos';
import { useHistory } from 'react-router-dom';
import Input from '../../../../../shared/components/Input';
import { useToast } from '../../../../../shared/hooks/toast';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import api from '../../../../../shared/services/api';
import getValidationErrors from '../../../../../shared/utils/getValidationErrors';
import DashboardHeader from '../../../components/DashboardHeader';

interface CategoryData {
  title: string;
}

const CreateCategory: React.FC = () => {
  Aos.init();

  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: CategoryData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          title: Yup.string().required('Nome obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/categories', data);

        history.push('/dashboard/categories');

        addToast({
          type: 'success',
          title: 'Categoria criada com sucesso',
          description: 'Uma nova categoria foi criada',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
        addToast({
          type: 'error',
          title: 'Erro ao criar categoria',
        });
      }
    },
    [addToast, history],
  );

  return (
    <>
      <DashboardHeader />
      <header className="bg-dark pt-9 pb-11 d-none d-md-block">
        <div className="container-md">
          <div className="row align-items-center">
            <div className="col">
              <h1 className="font-weight-bold text-white mb-2">
                Nova Categoria
              </h1>
            </div>
          </div>
        </div>
      </header>
      <main className="pb-8 pb-md-11 mt-md-n6" style={{ margin: 30 }}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12" data-aos="fade-up">
              <div
                className="card card-bleed shadow-light-lg mb-6"
                data-aos="fade-up"
              >
                <div className="card-header">
                  <h4 className="mb-0">Criar nova categoria</h4>
                </div>
                <div className="card-body">
                  <Form onSubmit={handleSubmit} ref={formRef}>
                    <div className="row">
                      <div className="col-12 col-md-12">
                        <div className="form-group">
                          <label htmlFor="name">Nome</label>
                          <Input name="title" id="name" type="text" />
                        </div>
                      </div>
                      <div className="col-12 ">
                        <button
                          className="btn btn-block btn-primary"
                          type="submit"
                        >
                          Criar categoria
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default CreateCategory;
