import React, { useRef, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import AOS from 'aos';
import getValidationErrors from '../../utils/getValidationErrors';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';

import Input from '../../components/Input';

interface SignUpData {
  name: string;
  email: string;
  password: string;
}

const SignUp: React.FC = () => {
  AOS.init();

  const { addToast } = useToast();

  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: SignUpData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatóio'),
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('E-mail inválido'),
          password: Yup.string().min(6, 'No mínimo 6 dígitos'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/users', data);

        history.push('/');

        addToast({
          type: 'success',
          title: 'Cadastro realizado com sucesso',
          description: 'Você já pode fazer seu login',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Ocorreu um erro ao fazer login, tente novamente',
        });
      }
    },
    [history, addToast],
  );

  return (
    <section className="section-border border-primary">
      <div className="container d-flex flex-column">
        <div className="row align-items-center justify-content-center no-gutters min-vh-100">
          <div
            className="col-12 col-md-5 col-lg-4 py-8 py-md-11"
            data-aos="fade-up"
          >
            <h1 className="mb-0 font-weight-bold text-center">Criar conta</h1>

            <p className="mb-6 text-center text-muted">
              Se candidate em uma das nossas vagas em minutos
            </p>

            <Form ref={formRef} onSubmit={handleSubmit} className="mb-6">
              <div className="form-group">
                <label htmlFor="email">Nome </label>
                <Input
                  name="name"
                  className="form-control"
                  id="email"
                  placeholder="Fulano de tal"
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">E-mail</label>
                <Input
                  name="email"
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="nome@email.com.br"
                />
              </div>

              <div className="form-group mb-5">
                <label htmlFor="password">Senha</label>
                <Input
                  name="password"
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder="Sua senha"
                />
              </div>

              <button className="btn btn-block btn-primary" type="submit">
                Criar conta
              </button>
            </Form>

            <p className="mb-0 font-size-sm text-center text-muted">
              Já tem uma conta? <Link to="/">Log in</Link>.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignUp;
