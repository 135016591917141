import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FiArrowLeft, FiCheck } from 'react-icons/fi';
import Header from '../../../components/Header';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { useToast } from '../../../../../shared/hooks/toast';
import api from '../../../../../shared/services/api';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Input from '../../../../../shared/components/Input';
import ReactLoading from 'react-loading';
import md from '../../../../../config/mdRenderer';

interface IParams {
  challenge: string;
}

interface IChallenge {
  id: string;
  title: string;
  description: string;
}

interface FinishChallengeData {
  link: string;
}

const ChallengeDetail: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { params } = useRouteMatch<IParams>();
  const { addToast } = useToast();
  const history = useHistory();

  const [challenge, setChallenge] = useState<IChallenge>();
  const [isFinished, setIsFinished] = useState();
  const [loading, setLoading] = useState(true);
  const html = useMemo(() => md.render(challenge?.description || ''), [
    challenge,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    api.get(`/challenges/${params.challenge}`).then(response => {
      setChallenge(response.data);
    });
  }, [params.challenge]);

  useEffect(() => {
    const checkIfIsFinished = async () => {
      try {
        await api.post('/checkIsFinished', {
          challenge_id: params.challenge,
        });

        return;
      } catch {
        setIsFinished(true);
      }
    };

    checkIfIsFinished();
  }, [params.challenge]);

  const handleSubmit = useCallback(
    async (data: FinishChallengeData) => {
      try {
        const { link } = data;

        await api.post('/finishedChallenges', {
          link,
          challenge_id: params.challenge,
        });

        history.push('/challenges/me');

        addToast({
          type: 'success',
          title: 'Desafio finalizado com sucesso',
          description: 'Você já pode esperar nosso contato',
        });
      } catch {
        addToast({
          type: 'error',
          title: 'Erro no envio do desafio',
          description:
            'Ocorreu um erro ao finalizar o desafio, tente novamente',
        });
      }
    },
    [history, addToast, params.challenge],
  );

  setTimeout(() => setLoading(false), 700);

  return (
    <>
      <Header />
      {loading && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '500px',
          }}
        >
          <ReactLoading type="bubbles" color="#335eea" width={100} />
        </div>
      )}
      {challenge && !loading && (
        <section className="pt-8 pt-md-11">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-md">
                <Link
                  to="/challenges/me"
                  className="font-weight-bold font-size-sm text-decoration-none mb-3"
                >
                  <FiArrowLeft className="fe fe-arrow-left mr-3" /> Todos os
                  desafios
                </Link>

                <h1 className="display-4 mb-2">{challenge.title}</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <hr className="my-6 my-md-8 border-gray-300" />
              </div>
            </div>
            <div className="row ">
              <div className="col-12 col-md-8">
                <div dangerouslySetInnerHTML={{ __html: html }} />
              </div>
              <div className="col-12 col-md-4">
                <div className="card shadow-light-lg mb-5 ">
                  {isFinished ? (
                    <div
                      className="card-body"
                      style={{ border: 'solid #42ba96' }}
                    >
                      <h4>Desafio enviado</h4>

                      <p className="font-size-sm text-gray-800 mb-5">
                        Nós já recebemos seu desafio, vamos analisá-lo e
                        entraremos em contato em breve
                      </p>

                      <div className="text-center">
                        <FiCheck color="#42ba96" size={60} />
                      </div>
                    </div>
                  ) : (
                    <div className="card-body ">
                      <h4>Enviar desafio</h4>

                      <p className="font-size-sm text-gray-800 mb-5">
                        Para poder nos enviar o seu desadio, será necessário
                        coloca-lo no controle de versionamento, você pode usar o
                        Github, ou Gitlab. Após verificar que está tudo ok,
                        coloque o link aqui e nos envie
                      </p>

                      <h6 className="font-weight-bold text-uppercase text-gray-700 mb-2">
                        Enviar o link
                      </h6>

                      <Form
                        ref={formRef}
                        onSubmit={handleSubmit}
                        className="mb-6"
                      >
                        <div className="form-group">
                          <Input
                            name="link"
                            className="form-control"
                            placeholder="https://github.com/LuanColeto/GoBarber"
                          />
                        </div>

                        <button
                          className="btn btn-block btn-primary"
                          type="submit"
                        >
                          Enviar
                        </button>
                      </Form>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ChallengeDetail;
