import React, { useCallback, useEffect, useState } from 'react';
import Aos from 'aos';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import api from '../../../../../shared/services/api';
import DashboardHeader from '../../../components/DashboardHeader';
import { useToast } from '../../../../../shared/hooks/toast';

interface IJob {
  id: string;
  title: string;
  location: string;
  period: string;
}

const Jobs: React.FC = () => {
  Aos.init();

  const [jobs, setJobs] = useState<IJob[]>([]);
  const { addToast } = useToast();

  useEffect(() => {
    api.get('/jobs').then(response => {
      setJobs(response.data);
    });
  }, []);

  const handleDeleteJob = useCallback(
    async (id: string) => {
      try {
        await api.delete(`/jobs/${id}`);

        setJobs(jobs.filter(job => job.id !== id));

        addToast({
          type: 'success',
          title: 'Vaga deletada',
          description: 'A vaga foi deletada com sucesso',
        });
      } catch {
        addToast({
          type: 'error',
          title: 'Erro ao deletar a vaga',
        });
      }
    },
    [addToast, jobs],
  );

  return (
    <>
      <DashboardHeader />
      <header className="bg-dark pt-9 pb-11 d-none d-md-block">
        <div className="container-md">
          <div className="row align-items-center">
            <div className="col">
              <h1 className="font-weight-bold text-white mb-2">Vagas</h1>
            </div>
            <div className="col-auto">
              <Link to="/dashboard/new-job" className="btn btn-sm btn-success">
                Criar nova vaga
              </Link>
            </div>
          </div>
        </div>
      </header>
      <main className="pb-8 pb-md-11 mt-md-n6" style={{ margin: 30 }}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12  " data-aos="fade-up">
              <div
                className="card card-bleed shadow-light-lg mb-6"
                data-aos="fade-up"
              >
                <div className="card-header">
                  <h4 className="mb-0">Todas as vagas</h4>
                </div>
                <div className="card-body">
                  <div className="card mb-5">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">Nome</th>
                              <th scope="col">Lugar</th>
                              <th scope="col">Periodo</th>
                              <th scope="col" />
                            </tr>
                          </thead>
                          <tbody>
                            {jobs.map(job => (
                              <tr key={job.id}>
                                <Link
                                  to={`/dashboard/applied-users/${job.id}`}
                                  style={{
                                    textDecoration: 'none',
                                    color: '#000',
                                  }}
                                >
                                  <td>{job.title}</td>
                                </Link>
                                <td>{job.location}</td>
                                <td>{job.period}</td>
                                <td>
                                  <div
                                    className="col-lg-6 col-5 text-right"
                                    style={{
                                      display: 'flex',
                                    }}
                                  >
                                    <Link
                                      to={`/dashboard/edit-job/${job.id}`}
                                      type="button"
                                      className="btn btn-sm btn-rounded-circle btn-secondary text-white"
                                      style={{ marginRight: 10 }}
                                    >
                                      <FiEdit />
                                    </Link>

                                    <button
                                      type="button"
                                      onClick={() => handleDeleteJob(job.id)}
                                      className="btn btn-sm btn-rounded-circle btn-danger text-white"
                                    >
                                      <FiTrash2 />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Jobs;
