import React, { useState, useEffect } from 'react';

import Header from '../../../components/DashboardHeader';
import { Link, useRouteMatch } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import api from '../../../../../shared/services/api';
import ReactLoading from 'react-loading';
import AOS from 'aos';

interface IParams {
  user: string;
}

interface IUser {
  id: string;
  name: string;
  email: string;
  about: string;
  avatar_url: string;
  finishedChallenge: [
    {
      id: string;
      created_at: Date;
      link: string;
      challenge: {
        title: string;
      };
    },
  ];
}

const AppliedUserChallenge: React.FC = () => {
  AOS.init();

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<IUser>();
  const { params } = useRouteMatch<IParams>();

  useEffect(() => {
    api.get(`/users/${params.user}`).then(response => {
      setUser(response.data);
    });
  }, [params.user]);

  setTimeout(() => setLoading(false), 700);

  return (
    <>
      <Header />
      {loading && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '500px',
          }}
        >
          <ReactLoading type="bubbles" color="#335eea" width={100} />
        </div>
      )}
      {!loading && user && (
        <>
          <header className="bg-dark pt-9 pb-11 d-none d-md-block">
            <div className="container-md">
              <div className="row align-items-center">
                <div className="col">
                  <h1 className="font-weight-bold text-white mb-2">
                    {user.name}
                  </h1>

                  <p className="font-size-lg text-white-75 mb-0">
                    Entrar em contato em{' '}
                    <a className="text-reset" href="mailto:dhgamache@gmail.com">
                      {user.email}
                    </a>
                  </p>
                </div>
                <div className="col-auto" />
              </div>
            </div>
          </header>

          <main className="pb-8 pb-md-11 mt-md-n6">
            <div className="container-md">
              <div className="row">
                <div className="col-12 col-md-3">
                  <div className="card card-bleed border-bottom border-bottom-md-0 shadow-light-lg">
                    <div className="collapse d-md-block" id="sidenavCollapse">
                      <div className="card-body">
                        <h6 className="font-weight-bold text-uppercase mb-3">
                          Usuário
                        </h6>

                        <ul className="card-list list text-gray-700 mb-6">
                          <li className="list-item ">
                            <Link
                              className="list-link text-reset"
                              to={`/dashboard/applied-user/${params.user}`}
                            >
                              Geral
                            </Link>
                          </li>
                          <li className="list-item">
                            <Link
                              className="list-link text-reset active"
                              to={`/dashboard/applied-user-challenges/${params.user}`}
                            >
                              Desafios
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-9" data-aos="fade-up">
                  <div className="card card-bleed shadow-light-lg">
                    <div className="card-header">
                      <h4 className="mb-0">Desafios finalizados</h4>
                    </div>
                    <div className="card-body">
                      <div className="list-group list-group-flush">
                        {user.finishedChallenge.map(finishedChallenge => (
                          <div
                            className="list-group-item"
                            key={finishedChallenge.id}
                          >
                            <div className="row align-items-center">
                              <div className="col">
                                <p className="mb-0">
                                  <p>{finishedChallenge.challenge.title}</p>
                                </p>
                              </div>
                              <div className="col-auto">
                                <span className="badge badge-pill badge-success-soft">
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={finishedChallenge.link}
                                    className="h6 text-uppercase font-weight-bold"
                                  >
                                    {finishedChallenge.link}
                                  </a>
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  {/* <p className="text-center mb-0"> */}
                  {/*  <small className="text-muted"> */}
                  {/*    Clique aqui para deletar a conta do usuário */}
                  {/*    <a className="text-danger" href="#!"> */}
                  {/*      delete sua conta */}
                  {/*    </a> */}
                  {/*  </small> */}
                  {/* </p> */}
                </div>
              </div>
            </div>
          </main>
        </>
      )}
    </>
  );
};

export default AppliedUserChallenge;
