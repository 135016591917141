import React, { useEffect, useState } from 'react';
import FlatList from 'flatlist-react';
import { Link } from 'react-router-dom';
import ReactLoading from 'react-loading';
import AOS from 'aos';
import Typing from 'react-typing-animation';

import BannerImage from '../../../../../assets/img/covers/cover-3.jpg';
import StepImage from '../../../../../assets/img/photos/photo-15.jpg';
import StepImage2 from '../../../../../assets/img/photos/photo-14.jpg';
import StepImage3 from '../../../../../assets/img/photos/photo-13.jpg';
import JobsIllustration from '../../../../../assets/img/illustrations/illustration-3.png';
import Header from '../../../components/Header';
import api from '../../../../../shared/services/api';

interface ICategory {
  id: string;
  title: string;
  job: {
    id: string;
    title: string;
    location: string;
    period: string;
  };
}

interface IJob {
  id: string;
  title: string;
  location: string;
  period: string;
}

const Home: React.FC = () => {
  AOS.init();

  const [categories, setCategories] = useState<ICategory[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.get('/categories').then(response => {
      setCategories(response.data);
    });
  }, []);

  setTimeout(() => setLoading(false), 700);

  return (
    <>
      <Header />
      {loading && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '500px',
          }}
        >
          <ReactLoading type="bubbles" color="#335eea" width={100} />
        </div>
      )}
      {!loading && (
        <>
          <section
            data-jarallax
            data-speed=".8"
            className="pt-12 pb-10 pt-md-15 pb-md-14"
            style={{
              zIndex: 0,
              background: `url(${BannerImage})`,
              backgroundRepeat: 'no-repeat',
              overflow: 'hidden',
              backgroundSize: 'cover',
            }}
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-12 col-md-8 col-lg-6">
                  <h1
                    className="display-3 font-weight-bold text-white"
                    id="welcomeHeadingSource"
                  >
                    Temos vagas para <br />
                    <Typing loop speed={50}>
                      <span className="text-warning">Back end Developers</span>
                      <Typing.Backspace count={20} delay={1000} />
                      <span className="text-warning">Front end Developers</span>
                      <Typing.Backspace count={21} delay={1000} />
                      <span className="text-warning">Mobile Developers</span>
                      <Typing.Backspace count={21} delay={1000} />
                      <span className="text-warning">UI Designers</span>
                      <Typing.Backspace count={13} delay={1000} />
                      <span className="text-warning">UX Designers</span>
                      <Typing.Backspace count={13} delay={1000} />
                      <span className="text-warning">Redatores</span>
                      <Typing.Backspace count={9} delay={1000} />
                    </Typing>
                  </h1>

                  <p className="font-size-lg text-white-80 mb-6">
                    Estamos buscando os melhores talentos para o nosso time,
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="pt-8 pt-md-11">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-12 col-md-6">
                  <h6 className="text-uppercase text-primary font-weight-bold">
                    Processo seletivo
                  </h6>

                  <h2>Nosso processo seletivo é prático e rápido</h2>

                  <p className="lead text-muted mb-6 mb-md-0">
                    Nós deixamos o processo o mais automatizado possível
                  </p>
                </div>
                <div className="col-12 col-md-6 col-xl-5">
                  <div className="row no-gutters">
                    <div className="col-4">
                      <div
                        className="w-150 mt-9 p-1 bg-white shadow-lg"
                        data-aos="fade-up"
                        data-aos-delay="100"
                      >
                        <img src={StepImage3} className="img-fluid" alt="..." />
                      </div>
                    </div>
                    <div className="col-4">
                      <div
                        className="w-150 p-1 bg-white shadow-lg"
                        data-aos="fade-up"
                      >
                        <img src={StepImage2} className="img-fluid" alt="..." />
                      </div>
                    </div>
                    <div className="col-4 position-relative">
                      <div
                        className="w-150 mt-11 float-right p-1 bg-white shadow-lg"
                        data-aos="fade-up"
                        data-aos-delay="150"
                      >
                        <img src={StepImage} className="img-fluid" alt="..." />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="pt-8 pb-9 pt-md-11 pb-md-13">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-4">
                  <div className="row">
                    <div className="col-auto col-md-12">
                      <div className="row no-gutters align-items-center mb-md-5">
                        <div className="col-auto">
                          <a
                            href="#!"
                            className="btn btn-sm btn-rounded-circle btn-gray-400 disabled opacity-1"
                          >
                            <span>1</span>
                          </a>
                        </div>
                        <div className="col">
                          <hr className="d-none d-md-block mr-n7" />
                        </div>
                      </div>
                    </div>
                    <div className="col col-md-12 ml-n5 ml-md-0">
                      <h3>Se candidate em uma vaga.</h3>

                      <p className="text-muted mb-6 mb-md-0">
                        Veja as nossas vagas abaixo, após escolher uma crie uma
                        conta e mande seu currículo
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="row">
                    <div className="col-auto col-md-12">
                      <div className="row no-gutters align-items-center mb-md-5">
                        <div className="col-auto">
                          <a
                            href="#!"
                            className="btn btn-sm btn-rounded-circle btn-gray-400 disabled opacity-1"
                          >
                            <span>2</span>
                          </a>
                        </div>
                        <div className="col">
                          <hr className="d-none d-md-block mr-n7" />
                        </div>
                      </div>
                    </div>
                    <div className="col col-md-12 ml-n5 ml-md-0">
                      <h3>Faça o teste requisitado.</h3>

                      <p className="text-muted mb-6 mb-md-0">
                        Após avaliarmos o seu currículo daremos acesso ào
                        desafio para testarmos o seu conhecimento.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="row">
                    <div className="col-auto col-md-12">
                      <div className="row no-gutters align-items-center mb-md-5">
                        <div className="col-auto">
                          <a
                            href="#!"
                            className="btn btn-sm btn-rounded-circle btn-gray-400 disabled opacity-1"
                          >
                            <span>3</span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col col-md-12 ml-n5 ml-md-0">
                      <h3>Entrevista.</h3>

                      <p className="text-muted mb-0">
                        Caso passe no teste, te avisaremos para fazermos uma
                        entrevista.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="position-relative">
            <div className="shape shape-bottom shape-fluid-x svg-shim text-light">
              <svg
                viewBox="0 0 2880 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M720 28H0v28h2880V28h-720S1874 0 1440 0 720 28 720 28z"
                  fill="currentColor"
                />
              </svg>
            </div>
          </div>
          <section className="py-8 py-md-11 bg-gradient-light">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-12 col-md-6">
                  <h6 className="text-uppercase text-primary font-weight-bold">
                    Nossas vagas
                  </h6>

                  <h2>Veja todas as nossas vagas.</h2>

                  <p className="text-muted mb-6 mb-md-8">
                    Aqui você pode escolher a vaga que preferir
                  </p>
                </div>
                <div className="col-12 col-md-6">
                  <img src={JobsIllustration} className="img-fluid" alt="..." />
                </div>
              </div>
              <div className="row">
                {categories.map(category => (
                  <div className="col-6 col-md-6">
                    <div
                      className="card card-border border-primary shadow-light-lg mb-6 mb-md-8"
                      data-aos="fade-up"
                    >
                      <div className="card-body">
                        <h6 className="text-uppercase text-primary d-inline-block mb-5 mr-1">
                          {category.title}
                        </h6>

                        <div>
                          <div className="list-group list-group-flush">
                            <FlatList
                              key={category.job.id}
                              list={category.job}
                              renderItem={(item: IJob) => (
                                <Link
                                  className="list-group-item text-reset text-decoration-none"
                                  to={`/carrer/${item.id}`}
                                >
                                  <p className="font-weight-bold mb-1">
                                    {item.title}
                                  </p>
                                  <p className="font-size-sm text-muted mb-0">
                                    {item.period} · {item.location}
                                  </p>
                                </Link>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Home;
