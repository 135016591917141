import React, { useEffect, useState } from 'react';
import Header from '../../../components/Header';
import { Link } from 'react-router-dom';
import AOS from 'aos';

import Empty from '../../../../../assets/img/illustrations/empty.svg';
import api from '../../../../../shared/services/api';
import ReactLoading from 'react-loading';

interface IApplication {
  applications: [
    {
      id: string;
      job: {
        challenge: {
          id: string;
          title: string;
          description: string;
        };
      };
    },
  ];
}

interface IChallenge {
  id: string;
  title: string;
  description: string;
}

const MyChallenges: React.FC = () => {
  AOS.init();

  const [user, setUser] = useState<IApplication>();
  const [challenge, setChallenge] = useState<IChallenge>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.get('/profile').then(response => {
      setUser(response.data);
    });
  }, []);

  useEffect(() => {
    user?.applications.map(application => {
      return setChallenge(application.job.challenge);
    });
  }, [user]);

  setTimeout(() => setLoading(false), 700);

  return (
    <>
      <Header />
      {loading && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '500px',
          }}
        >
          <ReactLoading type="bubbles" color="#335eea" width={100} />
        </div>
      )}
      {user && !loading && (
        <>
          {challenge && (
            <section style={{ marginTop: 50 }}>
              <h2 className="text-center">Meus Desafios</h2>

              <div className="container">
                <div className="row">
                  {user.applications.map(application => (
                    <div
                      className="col-12 col-md-6 col-lg-4"
                      data-aos="fade-up"
                      key={application.id}
                    >
                      <div className="card  border-primary shadow-light-lg mb-6 mb-md-8 lift lift-lg">
                        <Link
                          to={`/challenge/${application.job.challenge.id}`}
                          style={{ textDecoration: 'none', color: '#000' }}
                        >
                          <div className="card-body text-center">
                            <div className="icon-circle bg-primary text-white mb-5">
                              <i className="fe fe-users" />
                            </div>

                            <h4 className="font-weight-bold">
                              {application.job.challenge.title}
                            </h4>
                          </div>
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          )}
          {!challenge && (
            <section className="section-border border-primary">
              <div className="container d-flex flex-column">
                <div className="row align-items-center justify-content-center no-gutters ">
                  <div
                    className="col-8 col-md-6 col-lg-7 offset-md-1 order-md-2 mt-auto mt-md-0 pt-8 pb-4 py-md-11"
                    data-aos="fade-up"
                  >
                    <img src={Empty} alt="..." className="img-fluid" />
                  </div>
                  <div
                    className="col-12 col-md-5 col-lg-4 order-md-1 mb-auto mb-md-0 pb-8 py-md-11"
                    data-aos="fade-up"
                  >
                    <h1 className="display-3 font-weight-bold text-center">
                      Ooops
                    </h1>

                    <p className="mb-5 text-center text-muted">
                      parece que ainda não temos nenhum desafio para você
                    </p>

                    <div className="text-center">
                      <Link className="btn btn-primary" to="/">
                        Voltar mais tarde
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
        </>
      )}
    </>
  );
};

export default MyChallenges;
