import React, { useRef, useEffect, InputHTMLAttributes } from 'react';

import { useField } from '@unform/core';

interface InputProps extends InputHTMLAttributes<HTMLSelectElement> {
  name: string;
}

const Select: React.FC<InputProps> = ({ name, ...rest }) => {
  const selectRef = useRef(null);
  const { registerField, fieldName, defaultValue } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'value',
    });
  }, [fieldName, name, registerField]);

  return (
    <select
      className="form-control"
      defaultValue={defaultValue}
      ref={selectRef}
      {...rest}
    />
  );
};

export default Select;
