import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { useRouteMatch, useHistory } from 'react-router-dom';
import api from '../../../../../shared/services/api';
import Header from '../../../components/Header';
import { useToast } from '../../../../../shared/hooks/toast';
import ReactLoading from 'react-loading';
import md from '../../../../../config/mdRenderer';

interface IParams {
  job: string;
}

interface IJob {
  id: string;
  title: string;
  description: string;
  location: string;
  period: string;
}

const JobDetail: React.FC = () => {
  const { params } = useRouteMatch<IParams>();
  const { addToast } = useToast();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [isApplied, setIsApplied] = useState(false);
  const [job, setJob] = useState<IJob>();
  const html = useMemo(() => md.render(job?.description || ''), [job]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    api.get(`/jobs/${params.job}`).then(response => {
      setJob(response.data);
    });
  }, [params.job]);

  useEffect(() => {
    const checkIfIsApplied = async () => {
      try {
        await api.post('/checkIsApplied', {
          job_id: params.job,
        });

        return;
      } catch {
        setIsApplied(true);
      }
    };

    checkIfIsApplied();
  }, [params.job]);

  const handleApplyForJob = useCallback(async () => {
    try {
      await api.post('/apply', {
        job_id: params.job,
      });

      addToast({
        type: 'success',
        title: 'Candidatura realizada com sucesso',
        description:
          'Você se candidatou com sucesso, entraremos em contato com mais detalhes em breve',
      });

      history.push('/jobs/me');
    } catch {
      addToast({
        type: 'error',
        title: 'Erro na candidatura',
        description:
          'Ocorreu um erro ao se candidatar nessa vaga, tente novamente mais tarde',
      });
    }
  }, [params.job, addToast, history]);

  setTimeout(() => setLoading(false), 700);

  return (
    <>
      <Header />
      {loading && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '500px',
          }}
        >
          <ReactLoading type="bubbles" color="#335eea" width={100} />
        </div>
      )}
      {job && !loading && (
        <section className="pt-8 pt-md-11">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-md">
                <Link
                  to="/"
                  className="font-weight-bold font-size-sm text-decoration-none mb-3"
                >
                  <FiArrowLeft className="fe fe-arrow-left mr-3" /> Todas as
                  vagas
                </Link>

                <h1 className="display-4 mb-2">{job.title}</h1>

                <p className="font-size-lg text-gray-700 mb-5 mb-md-0">
                  {job.location} · {job.period}
                </p>
              </div>
              <div className="col-auto">
                {isApplied ? (
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{ backgroundColor: '#42ba96', cursor: 'default' }}
                  >
                    Você já se candidatou
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={handleApplyForJob}
                    className="btn btn-primary"
                  >
                    Se candidatar
                  </button>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <hr className="my-6 my-md-8 border-gray-300" />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-8">
                <div dangerouslySetInnerHTML={{ __html: html }} />
              </div>
              <div className="col-12 col-md-4">
                <div className="card shadow-light-lg mb-5 ">
                  <div className="card-body">
                    <h4>Precisa de ajuda?</h4>

                    <p className="font-size-sm text-gray-800 mb-5">
                      Precisa de ajuda com alguma coisa, ou alguma coisa não
                      está dando certo? Fale conosco que ficaremos felizes em
                      ajudare
                    </p>

                    <h6 className="font-weight-bold text-uppercase text-gray-700 mb-2">
                      Nos mande mensagem
                    </h6>

                    <p className="font-size-sm mb-5">
                      <a href="tel:555-123-4567" className="text-reset">
                        (555) 123-4567
                      </a>
                    </p>

                    <h6 className="font-weight-bold text-uppercase text-gray-700 mb-2">
                      Nos mande um e-mail
                    </h6>

                    <p className="font-size-sm mb-0">
                      <a
                        href="mailto:support@goodthemes.co"
                        className="text-reset"
                      >
                        support@goodthemes.co
                      </a>
                    </p>
                  </div>
                </div>

                <div className="card shadow-light-lg">
                  <div className="card-body">
                    <h4>Não encontrou o que procura?</h4>

                    <p className="font-size-sm text-gray-800">
                      Se você quer se canditar para uma vaga que não está
                      aparecendo, fale conosco e analisaremos sua proposta
                    </p>

                    <a
                      href="#!"
                      className="font-weight-bold font-size-sm text-decoration-none"
                    >
                      Fale conosco
                      <FiArrowRight className="fe fe-arrow-right ml-3" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default JobDetail;
