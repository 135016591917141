import React, { useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import AOS from 'aos';

import Header from '../../../components/DashboardHeader';

import Empty from '../../../../../assets/img/illustrations/empty.svg';
import api from '../../../../../shared/services/api';
import ReactLoading from 'react-loading';
import Avatar from '../../../../../assets/img/illustrations/profile.svg';

interface IParams {
  job: string;
}

interface IApplication {
  id: string;
  user: {
    id: string;
    name: string;
    email: string;
    avatar_url: string;
  };
}
interface IUser {
  id: string;
  name: string;
  email: string;
  avatar_url: string;
}

const AppliedUsers: React.FC = () => {
  AOS.init();

  const { params } = useRouteMatch<IParams>();

  const [appliedJob, setAppliedJob] = useState<IApplication[]>([]);
  const [user, setUser] = useState<IUser>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.get(`/apply/${params.job}`).then(response => {
      setAppliedJob(response.data);
    });
  }, [params.job]);

  useEffect(() => {
    appliedJob.map(application => {
      return setUser(application.user);
    });
  }, [user, appliedJob]);

  setTimeout(() => setLoading(false), 700);

  return (
    <>
      <Header />
      {loading && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '500px',
          }}
        >
          <ReactLoading type="bubbles" color="#335eea" width={100} />
        </div>
      )}
      {user && !loading && (
        <>
          {user && (
            <section style={{ marginTop: 50 }}>
              <h2 className="text-center">Candidatos</h2>

              <div className="container">
                <div className="row">
                  {appliedJob.map(application => (
                    <div
                      className="col-12 col-md-4 col-lg-"
                      data-aos="fade-up"
                      key={application.id}
                    >
                      <Link
                        to={`/dashboard/applied-user/${application.user.id}`}
                        style={{ textDecoration: 'none' }}
                      >
                        <div className="card   shadow-light mb-6 mb-md-8 lift lift-lg">
                          <div className="card-body text-center">
                            <div
                              className="avatar avatar-xl"
                              style={{ marginBottom: 10 }}
                            >
                              {application.user.avatar_url ? (
                                <img
                                  className="avatar-img rounded-circle"
                                  src={application.user.avatar_url}
                                  alt="..."
                                />
                              ) : (
                                <img
                                  className="avatar-img rounded-circle"
                                  src={Avatar}
                                  alt="..."
                                />
                              )}
                            </div>
                            <h4 className="font-weight-bold">
                              {application.user.name}
                            </h4>

                            <p className="text-gray-700 mb-5">
                              {application.user.email}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          )}
          {!user && (
            <section className="section-border border-primary">
              <div className="container d-flex flex-column">
                <div className="row align-items-center justify-content-center no-gutters ">
                  <div
                    className="col-8 col-md-6 col-lg-7 offset-md-1 order-md-2 mt-auto mt-md-0 pt-8 pb-4 py-md-11"
                    data-aos="fade-up"
                  >
                    <img src={Empty} alt="..." className="img-fluid" />
                  </div>
                  <div
                    className="col-12 col-md-5 col-lg-4 order-md-1 mb-auto mb-md-0 pb-8 py-md-11"
                    data-aos="fade-up"
                  >
                    <h1 className="display-3 font-weight-bold text-center">
                      Ooops
                    </h1>

                    <p className="mb-5 text-center text-muted">
                      parece que ainda não se candidatou em nenhuma vaga
                    </p>

                    <div className="text-center">
                      <Link className="btn btn-primary" to="/">
                        Ver todas as vagas
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
        </>
      )}
    </>
  );
};

export default AppliedUsers;
